import React from "react"

import Meta from "../utilities/seo"

import { PageSectionWrap, PageTitle } from "../components/objects"
import Page from "../components/page"
import { PageBkg } from "../components/objects"

import Photo from "../assets/images/content/shutterstock_431594938.jpg"

export default () => {
  return (
    <>
      <Meta title="Switch40 - UK Staffing Agency" />
      <Page photo={Photo}>
        <PageBkg>
          <PageSectionWrap bkg="gold">
            <PageTitle dark>404! Switch to another page </PageTitle>
          </PageSectionWrap>
        </PageBkg>
      </Page>
    </>
  )
}
